import React from "react";

export default function SellSettings() {
  return (
    <div class="container-fluid sell-settings py-4 sellpx bg-white bgwheight">
      <h1>Sell Settings</h1>
     <div class="form-check mb-3">
        <input
          type="checkbox"
          class="form-check-input"
          id="makeAvailableForPurchase"
        />
        <label class="form-check-label fw-500" for="makeAvailableForPurchase">
          Make available for purchase
        </label>
        <small class="form-text text-muted">
          Let your readers buy your publication through.
        </small>
      </div>
      <div class="price-settings">
        <h2>Price settings</h2>
        <div class="form-group row">
          <label for="unitPrice" class="col-sm-2 col-form-label">
            Unit Price
          </label>
          <div class="col-sm-10">
            <input
              type="number"
              class="form-control unit-price"
              id="unitPrice"
              value="0.50"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="defaultCurrency" class="col-sm-2 col-form-label">
            Default Currency
          </label>
          <div class="col-sm-10">
            <select class="form-control default-currency" id="defaultCurrency">
              <option value="USD">USD - US Dollar</option>
            </select>
          </div>
        </div>
        <small class="form-text text-muted">
          Minimum 0.50 USD. commission fee of 0% and payment provider fee
          apply.
        </small>
      </div>
    </div>
  );
}
