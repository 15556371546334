import React from "react";

export default function PreviewPages() {
  return (
    <>
      <div className="container-fluid py-5 sellpx bg-white bgwheight">
        <h1>Preview pages</h1>
        <p>
          Choose which pages readers can see before purchasing your publication.
          your publication is currently marked as part of your preview.
        </p>
      </div>
    </>
  );
}
