import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import * as pdfjsLib from "pdfjs-dist";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import {
  FaPlay,
  FaPause,
  FaSearchPlus,
  FaSearchMinus,
  FaDownload,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
  FaCompress,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaList,
  FaTh,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import { getCookie } from "./Utils";
import { FiSave } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import flipSound from "../Sound/page-flip.ogg";
import { useDispatch, useSelector } from "react-redux";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

GlobalWorkerOptions.workerSrc = `https://api.silocloud.io/pdfworker/pdf.worker.min.js`;

function Flipbook2({ fileDataFolder }) {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState("");
  const [pages, setPages] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [playInterval, setPlayInterval] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tooltipOpenDiscard, setTooltipOpenDiscard] = useState(false);
  const [tooltipOpenSave, setTooltipOpenSave] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [data, setData] = useState([]);
  const bookRef = useRef(null);
  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [getData, setGetData] = useState(false);
  const [audio] = useState(new Audio(flipSound));
  //new loadPDF
  const [thumbnails, setThumbnails] = useState([]);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const canvasRef = useRef(null); // Used for first page thumbnail
  const [isCanvasReady, setIsCanvasReady] = useState(false);

  useEffect(() => {
    if (fileDataFolder && isCanvasReady) {
      if (!canvasRef.current) {
        console.error("Canvas not found");
        return;
      }

      const loadPdfThumbnails = async (fileData) => {
        try {
          setLoading(true);
          setThumbnails([]);

          const loadingTask = getDocument({ data: fileData });
          const pdf = await loadingTask.promise;
          const numPages = pdf.numPages;
          const thumbnailList = [];

          for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
            const page = await pdf.getPage(pageNumber);
            const viewport = page.getViewport({ scale: 1.5 });

            // Create a new canvas for each page
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            // Render the page to the new canvas
            await page.render({ canvasContext: context, viewport }).promise;

            // Get the image data URL from the canvas
            const thumbnail = canvas.toDataURL("image/png", 1.0);
            thumbnailList.push(thumbnail);
          }

          // Set the generated thumbnails in the state
          setThumbnails(thumbnailList);
        } catch (error) {
          console.error("Error loading PDF:", error);
        } finally {
          setLoading(false);
        }
      };

      const generateFirstPageThumbnail = async (fileData) => {
        try {
          const pdf = await getDocument(fileData).promise;
          const page = await pdf.getPage(1); // First page only
          const viewport = page.getViewport({ scale: 1 });

          const canvas = canvasRef.current;
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render the first page to the single canvas (for file generation)
          await page.render({ canvasContext: context, viewport }).promise;

          // Convert the canvas to base64 and then to a File object
          const base64Data = canvas.toDataURL("image/png");
          const file = base64ToFile(base64Data, "thumbnail.png");
          setThumbnailFile(file); // Store the file for API upload
        } catch (error) {
          console.error(
            "Error generating thumbnail for the first page:",
            error
          );
        }
      };

      // Helper function to convert base64 data to a File object
      const base64ToFile = (base64Data, fileName) => {
        const arr = base64Data.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
      };

      // Read the PDF file data
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const typedArray = new Uint8Array(e.target.result);

        // Clone the ArrayBuffer to avoid detachment
        const typedArrayClone = typedArray.slice(0);

        // Generate thumbnails for all pages with a cloned array
        loadPdfThumbnails(typedArray);

        // Generate a file for the first page thumbnail with another cloned array
        generateFirstPageThumbnail(typedArrayClone);
      };
      fileReader.readAsArrayBuffer(fileDataFolder);
    }
  }, [fileDataFolder, isCanvasReady, canvasRef.current]);

  useEffect(() => {
    if (canvasRef.current) {
      console.log(canvasRef.current);
      setIsCanvasReady(true);
    }
  }, [canvasRef.current]);

  const handleFlip = () => {
    if (!isMuted) {
      audio.play();
    }
  };

  //show new collection in dropdown list -Show Collection API
  const fetchData = async () => {
    // setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/collection`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.collections);
      setGetData(!getData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    renderDropdown();
  }, [data]);

  useEffect(() => {
    const handleFlip = () => {
      if (audioRef.current && !isMuted) {
        audioRef.current.play();
      }
    };

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [isMuted]);

  useEffect(() => {
    if (isPlaying) {
      const intervalId = setInterval(() => {
        bookRef.current.pageFlip().flipNext();
      }, 1500); // Change the interval time as needed
      setPlayInterval(intervalId);
    } else {
      clearInterval(playInterval);
    }

    return () => clearInterval(playInterval);
  }, [isPlaying]);

  const [selectedCollectionId, setSelectedCollectionId] = useState(null);

  if (isLoading) {
    return (
      <div className="uploadloader1">
        <Oval
          visible={true}
          height="100"
          width="100"
          color="#4fa94d"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
        <div className="py-3">Saving your file..</div>
      </div>
    );
  }

  const toggleTableOfContents = () =>
    setShowTableOfContents(!showTableOfContents);

  const handleThumbnailClick = (index) => {
    bookRef.current.pageFlip().turnToPage(index);
  };

  const handlePrevPage = () => bookRef.current.pageFlip().flipPrev();
  const handleNextPage = () => bookRef.current.pageFlip().flipNext();
  const handleFirstPage = () => bookRef.current.pageFlip().turnToPage(0);
  const handleLastPage = () => {
    const totalPages = bookRef.current.pageFlip().getPageCount();
    bookRef.current.pageFlip().turnToPage(totalPages - 1);
  };

  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));

  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));

  const toggleMute = () => setIsMuted(!isMuted);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  // download the pdf with watermark
  const downloadClick = () => {
    const watermarkText = "Powered By AxiomWebControl";
    // const projectLink = "https://publisher.silocloud.io";
    const projectLink = "https://publisher.axiomwebcontrol.com/";

    // Show loading toaster
    const loadingToastId = toast.loading("Preparing your download...");

    // Build PDF content
    const docDefinition = {
      content: thumbnails.map((thumbnail, index) => ({
        stack: [
          {
            image: thumbnail, // Ensure `thumbnail` is a valid base64 image string
            width: 595.28,
            height: 841.89,
            absolutePosition: { x: 0, y: 0 },
          },
          {
            text: watermarkText,
            fontSize: 16,
            color: "red",
            opacity: 0.2,
            absolutePosition: { x: 10, y: 770 },
            margin: [0, 0, 0, 0],
          },
          {
            text: projectLink,
            fontSize: 10,
            color: "blue",
            opacity: 0.5,
            absolutePosition: { x: 10, y: 787 },
            link: projectLink,
            margin: [0, 0, 0, 0],
          },
        ],
        pageBreak: index < thumbnails.length - 1 ? "after" : undefined,
      })),
    };

    // Create and download the PDF
    pdfMake.createPdf(docDefinition).download("flipbook.pdf", () => {
      // Dismiss the loading toaster and show success toaster
      toast.dismiss(loadingToastId);
      toast.success("PDF downloaded successfully!");
    });
  };

  // share the pdf
  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  //click on save button then open this swal with dropdown for select collection name
  const saveClick = () => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    }

    Swal.fire({
      title: "Save as",
      html: `<div style="display: flex; align-items: center;">
               <select id="swal-select" class="form-select form-select-lg swal2-input" aria-label=".form-select-lg example">
               ${
                 data && data.length > 0
                   ? Array.from(
                       new Set(data.map((item) => item.collection_name))
                     ) // Extract unique collection names
                       .map((name) => {
                         const item = data.find(
                           (item) => item.collection_name === name
                         ); // Find the corresponding item
                         return loading
                           ? "<p>Loading...</p>"
                           : `<option value="${item.id}">${item.collection_name}</option>`;
                       })
                       .join("")
                   : '<option value="">Home</option>'
               }
             </select>
                
                <button id="add-collection-btn" class="swal2-confirm swal2-styled" style="background-color: #3085d6; color: white; border: none; padding: 8px 12px; cursor: pointer; margin-left: 10px;">
                  <i class="fas fa-plus"></i>
                </button>
            </div>`,
      inputPlaceholder: "Enter your Collection name",
      inputAttributes: {
        maxlength: 30,
      },
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        const selectElement = document.getElementById("swal-select");
        selectElement.addEventListener("change", (event) => {
          const selectedId = event.target.value;
          setSelectedCollectionId(parseInt(selectedId));
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const selectedValue = document.getElementById("swal-select").value;
        handleClick(selectedValue);
      }
    });

    // Handling the plus icon click to add a new collection
    document
      .getElementById("add-collection-btn")
      .addEventListener("click", handleAddCollection);
    //  }
  };

  function renderDropdown() {
    const selectElement = document.getElementById("swal-select");
    if (selectElement) {
      selectElement.innerHTML = data
        .map(
          (item) =>
            `<option value="${item.id}">${item.collection_name}</option>`
        )
        .join("");
    }
  }

  function addNewCollection(collectionName) {
    const newCollection = {
      id: data.length + 1,
      collection_name: collectionName,
    };

    data.push(newCollection);
    renderDropdown();
  }

  //swal of create new collection
  const handleAddCollection = () => {
    Swal.fire({
      title: "Add New Collection",
      input: "text",
      inputPlaceholder: "Enter new collection name",
      inputAttributes: {
        maxlength: 20,
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        sendCollection(result.value);
        // Optionally update
        addNewCollection(result.value);
        setDropdownValue(result.value);
        saveClick();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //  Cancel is clicked, open main "Save as" modal
        saveClick();
      }
    });
  };

  //create new collection name and save into dropdown list -Create Collection API
  const sendCollection = async (collectionName) => {
    const formData = new FormData();
    formData.append("collection_name", collectionName);

    const sendRequest = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/collection`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const storeVal = await response.json();
      if (storeVal.status === false) {
        const errorMessage = storeVal.errors[0];
        throw new Error(errorMessage);
      }

      fetchData();
      return storeVal;
    };

    toast.promise(sendRequest(), {
      pending: "Creating collection...",
      success: {
        render({ data }) {
          return "Collection created successfully!";
        },
      },
      error: {
        render({ data }) {
          return data.message || "Failed to create collection";
        },
      },
    });
  };

  //save the pdf in collection page -Upload pdf API
  const selectedCollectionIdNum = parseInt(selectedCollectionId);

  const handleClick = async (selectedValue) => {
    // setLoading(true);
    setIsLoading(true);
    try {
      const formData = new FormData();
      const filename = localStorage.getItem("Filename");
      formData.append("pdf_file", fileDataFolder, filename);
      formData.append("collection_id", selectedValue);
      formData.append("thumbnail", thumbnailFile);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/upload`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.status) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Your file has been Uploaded.",
          showConfirmButton: false,
          timer: 1000,
        });
        setIsLoading(false);
        navigate("/collection");
      } else {
        toast.error(result.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDiscardTooltip = () => setTooltipOpenDiscard(!tooltipOpenDiscard);
  const toggleSaveTooltip = () => setTooltipOpenSave(!tooltipOpenSave);

  return (
    <>
      <ToastContainer />
      <div className="flipbook-container" style={{ overflow: "hidden" }}>
        <canvas ref={canvasRef} style={{ display: "none" }} />
        {showTableOfContents && (
          <div className="thumbnails-sidebar">
            {thumbnails.map((thumbnail, index) => (
              <div key={index} className="thumbnail-container">
                <img
                  src={thumbnail}
                  alt={`Page ${index + 1}`}
                  className="thumbnail"
                  onClick={() => handleThumbnailClick(index)}
                />
                <div className="page-number text-center">{index + 1}</div>
              </div>
            ))}
          </div>
        )}
        {isLoading || thumbnails.length <= 0 ? (
          <div className="uploadloader">
            <Oval
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <div className="py-3">Uploading....</div>
          </div>
        ) : (
          thumbnails.length > 0 && (
            <div
              className="flipbook-wrapper"
              style={{ transform: `scale(${zoomLevel})`, position: "relative" }}
            >
              <HTMLFlipBook
                width={400}
                height={500}
                ref={bookRef}
                onFlip={handleFlip}
                mobileScrollSupport={true}
                showCover={true}
              >
                {thumbnails.map((thumbnail, index) => (
                  <div key={index} className="demoPage">
                    <img
                      src={thumbnail}
                      alt={`PDF Thumbnail ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </div>
                ))}
              </HTMLFlipBook>
            </div>
          )
        )}

        <div className="toolbox d-flex justify-content-center">
          <div className="tool text-center" onClick={handleFirstPage}>
            <FaAngleDoubleLeft />
          </div>
          <div
            className="tool text-center"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </div>
          <div
            className="tool text-center"
            onClick={handleNextPage}
            disabled={currentPage === numPages}
          >
            <FaChevronRight />
          </div>
          <div className="tool text-center" onClick={handleLastPage}>
            <FaAngleDoubleRight />
          </div>
          <div className="tool text-center" onClick={toggleTableOfContents}>
            {showTableOfContents ? <FaTh /> : <FaList />}
          </div>
          <div className="tool text-center" onClick={zoomIn}>
            <FaSearchPlus />
          </div>

          <div className="tool text-center" onClick={zoomOut}>
            <FaSearchMinus />
          </div>
          <div className="tool text-center" onClick={togglePlayPause}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </div>
          <div className="tool text-center" onClick={toggleMute}>
            {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
          </div>
          <div className="tool text-center" onClick={toggleFullScreen}>
            {isFullScreen ? <FaCompress /> : <FaExpand />}
          </div>
          <div className="tool text-center" onClick={downloadClick}>
            <FaDownload />
          </div>
        </div>

        <div className=" d-flex justify-content-around gap-3 discardbutton">
          {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
            <>
              <div className="mt-2 mt-sm-0 text-center">
                <Link to="/">
                  <button
                    id="discardButton"
                    type="button"
                    className="btn btn-danger btn-sm  d-flex justify-content-center align-items-center mx-auto"
                  >
                    <RiDeleteBin6Line className="btnlogo" />
                    <span className="text-light btntext">Discard</span>
                  </button>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpenDiscard}
                    target="discardButton"
                    toggle={toggleDiscardTooltip}
                  >
                    Discard the current file
                  </Tooltip>
                </Link>
              </div>
              <div className="mt-2 mt-sm-0 text-center">
                <button
                  id="saveButton"
                  type="button"
                  className="btn btn-success btn-sm d-flex justify-content-center align-items-center mx-auto"
                  onClick={saveClick}
                >
                  <FiSave className="btnlogo" />
                  <span className="text-light btntext">Save</span>
                </button>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpenSave}
                  target="saveButton"
                  toggle={toggleSaveTooltip}
                >
                  Save the current file
                </Tooltip>
              </div>
            </>
          ) : (
            <>
              <div className="mt-2 mt-sm-0 text-center">
                <Link to="/">
                  <button
                    id="discardButton"
                    type="button"
                    className="btn gradient-button  btnsizeaxiom  d-flex justify-content-center align-items-center mx-auto"
                  >
                    <RiDeleteBin6Line className="btnlogo" />
                    <span className="btntext">Discard</span>
                  </button>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpenDiscard}
                    target="discardButton"
                    toggle={toggleDiscardTooltip}
                  >
                    Discard the current file
                  </Tooltip>
                </Link>
              </div>
              <div className="mt-2 mt-sm-0 text-center">
                <button
                  id="saveButton"
                  type="button"
                  className="btn gradient-button  btnsizeaxiom  d-flex justify-content-center align-items-center mx-auto"
                  onClick={saveClick}
                >
                  <FiSave className="btnlogo" />
                  <span className="text-light btntext">Save</span>
                </button>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpenSave}
                  target="saveButton"
                  toggle={toggleSaveTooltip}
                >
                  Save the current file
                </Tooltip>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default Flipbook2;
