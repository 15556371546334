import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { VscBook } from "react-icons/vsc";
import Publications from "./Publications";
import Followers from "./Followers";
import { SlUserFollow } from "react-icons/sl";
import { GoShareAndroid } from "react-icons/go";
import { AiOutlineEdit } from "react-icons/ai";
import profileimage from "../Images/profile.png";
import { getCookie } from "./Utils";

const Publicprofile = () => {
  const [toggle, setToggle] = useState(1);
  console.log(toggle);

  const [activeIconTab, setActiveIconTab] = useState("1");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const [count, setCount] = useState(0);

  return (
    <div className="container-fluid">
      <div className="row py-5">
        <div className="col-md-2">
          <div className="profile-pic d-flex justify-content-center">
            <div className="profile-circle">
              <img
                src={getCookie("profile_pic")}
                alt=""
                className="roundedcircle"
                width={180}
              />
            </div>
          </div>

          <div className="py-2 d-flex justify-content-center">
            <button type="button" class="btn btn-primary btnpadding">
              <AiOutlineEdit className="btnlogo" />
              <span>Edit Profile</span>
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <button type="button" class="btn btn-dark">
              <GoShareAndroid className="btnlogo" />
              <span>Share Profile</span>
            </button>
          </div>
        </div>
        <div className="col-md-10">
          <h1>{getCookie("username")}</h1>
        </div>
      </div>
      <div className="container-fluid">
        <div className="my-3 d-flex justify-content-between">
          <Nav tabs className="p-4 nav-tabs">
            <NavItem className="px-1">
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "1" })}
                onClick={(ev) => {
                  setToggle(1);
                  ev.preventDefault();
                  toggleIconTab("1");
                }}
              >
                <VscBook className="fs-4" />{" "}
                <span className="navlinktext px-1">Publications ({count})</span>
              </NavLink>
            </NavItem>
            <NavItem className="px-1">
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "2" })}
                onClick={(ev) => {
                  setToggle(2);
                  ev.preventDefault();
                  toggleIconTab("2");
                }}
              >
                <SlUserFollow />
                <span className="navlinktext px-2">Followers (0)</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div>
          {toggle === 1 ? (
            <Publications count={count} setCount={setCount} />
          ) : (
            <Followers />
          )}
        </div>
      </div>
    </div>
  );
};

export default Publicprofile;
