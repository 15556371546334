import React, { useState } from "react";
import { IoHomeOutline } from "react-icons/io5";
import { BsCurrencyDollar } from "react-icons/bs";
import { BsBook } from "react-icons/bs";
import Upload from "./Upload";
import Publishe from "./Publishe";
import LandingpageHome from "./LandingpageHome";
import Sales from "./Sales";
import { Link } from "react-router-dom";
import { FaRegFolder } from "react-icons/fa6";
import { TfiStatsUp } from "react-icons/tfi";
import Statistics from "./Statistics";
import { GrDocumentImage } from "react-icons/gr";
import { GrArticle } from "react-icons/gr";
import { FaRegFolderClosed } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import SocialPost from "./SocialPost";
import Articles from "./Articles";

export default function Landingpage() {
  const [activeSection, setActiveSection] = useState("home");

  const handleClick = (section) => {
    setActiveSection(section);
  };

  const renderContent = () => {
    switch (activeSection) {
      case "home":
        return <LandingpageHome />;
      case "upload":
        return <Upload />;
      case "articles":
        return <Articles />;
      case "socialposts":
        return <SocialPost />;
      case "publish":
        return <Publishe />;
      // case "statistics":
      //   return <Statistics />;
      // case "sales":
      //   return <Sales />;
      case "upload":
        return <Upload />;
      default:
      case "home":
        return <LandingpageHome />;
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-2 d-none d-md-block bg-white publishSidebar border-end">
          <div className="py-2">
            <button class="btn btn-primary uploadbutton" type="button">
              Upload
            </button>
          </div>
          <div className="">
            <div
              className={`sidebar-item ${
                activeSection === "home" ? "active" : ""
              }`}
              onClick={() => handleClick("home")}
            >
              <IoHomeOutline className="sidebar-icon" />
              <span className="sidebar-text">Home</span>
            </div>
            <div className="dropdown1">
              <div className="dropdown-header" onClick={toggleDropdown}>
                <FaRegFolderClosed className="sidebar-icon" />
                <span className="sidebar-text">My Library</span>
                <span className="dropdown-arrow">
                  {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              </div>
              {isOpen && (
                <div className="dropdown-content">
                  <div
                    className={`dropdown-item ${
                      activeSection === "publish" ? "active" : ""
                    }`}
                    onClick={() => handleClick("publish")}
                  >
                    <BsBook className="sidebar-icon" />
                    <span className="sidebar-text">Publications</span>
                  </div>
                  <div
                   className={`dropdown-item ${
                    activeSection === "articles" ? "active" : ""
                  }`}
                    onClick={() => handleClick("articles")}
                  >
                    <GrArticle className="sidebar-icon" />
                    <span className="sidebar-text">Articles</span>
                  </div>
                  <div
                   className={`dropdown-item ${
                    activeSection === "socialposts" ? "active" : ""
                  }`}
                  onClick={() => handleClick("socialposts")}>
                    <GrDocumentImage className="sidebar-icon" />
                    <span className="sidebar-text">Social Posts</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`sidebar-item ${
                activeSection === "publish" ? "active" : ""
              }`}
              onClick={() => handleClick("publish")}
            >
              <BsBook className="sidebar-icon" />
              <span className="sidebar-text">Publications</span>
            </div>
            <div
              className={`sidebar-item ${
                activeSection === "statistics" ? "active" : ""
              }`}
              onClick={() => handleClick("statistics")}
            >
              <TfiStatsUp className="sidebar-icon" />
              <span className="sidebar-text">Statistics</span>
            </div>
            <div
              className={`sidebar-item ${
                activeSection === "sales" ? "active" : ""
              }`}
              onClick={() => handleClick("sales")}
            >
              <BsCurrencyDollar className="sidebar-icon" />
              <span className="sidebar-text">Sales</span>
            </div>

            <Link to="/collection">
              <div
                className={`sidebar-item ${
                  activeSection === "collections" ? "active" : ""
                }`}
                onClick={() => handleClick("collections")}
              >
                <FaRegFolder className="sidebar-icon" />
                <span className="sidebar-text">Collections</span>
              </div>
            </Link> 
            <div className="plan-card">
              <p>
                Current plan: <strong>Basic</strong>
              </p>
              <p>Get 3 months for less than half price</p>
              <button className="upgrade-button">
                <i className="fas fa-crown"></i> Upgrade
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-10 col-12 overflow-auto landingpage">
          {renderContent()}
        </div>
      </div>
    </div>
  );
}
