import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Flipbook from "./Components/Flipbook";
import Home from "./Components/Home";
import "bootstrap/dist/css/bootstrap.css";
import { ThemeProvider } from "./Context/ThemeContext";
import Collection from "./Components/Collection";
import Dropdown from "./Components/Dropdown";
import Shimmer from "./Components/Shimmer";
import "./index.css";
import "./CSS/CMS.css";
import "./CSS/Flipbook.css";
import "./CSS/Common.css";
import "./CSS/Header.css";
import "./CSS/footer.css";
import "./CSS/Publish&Sell.css";
import FlipbookFetch from "./Components/FlipbookFetch";
import Landingpage from "./Components/Landingpage";
import Upload from "./Components/Upload";
import PublishFlipbook from "./Components/PublishFlipbook";
import Publishe from "./Components/Publishe";
import LandingpageHome from "./Components/LandingpageHome";
import Sell from "./Components/Sell";
import Sales from "./Components/Sales";
import SellPublishe from "./Components/SellPublishe";
import ClickToFlipbook from "./Components/ClickToFlipbook";
import { Provider } from "react-redux";
import { store, persistor } from "./Components/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import Publicprofile from "./Components/Publicprofile";
import SharePublishing from "./Components/SharePublishing";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,

    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/collection",
        element: <Collection />,
      },
      {
        path: "flipbook",
        element: <Flipbook />,
      },
      {
        path: "dropdown",
        element: <Dropdown />,
      },
      {
        path: "shimmer",
        element: <Shimmer />,
      },
      {
        path: "flipbookPage",
        element: <FlipbookFetch />,
      },
      {
        path: "home",
        element: <Landingpage />,
      },
      {
        path: "upload",
        element: <Upload />,
      },
      {
        path: "publishFlipbook",
        element: <PublishFlipbook />,
      },
      {
        path: "publishe",
        element: <Publishe />,
      },
      {
        path: "landingpagehome",
        element: <LandingpageHome />,
      },
      {
        path: "sell",
        element: <Sell />,
      },
      {
        path: "sales",  
        element: <Sales />,
      },
      {
        path: "sellpublishe/:pdf_id",
        element: <SellPublishe />,
      },
      {
        path: "clickToFlipbook/:pdf_id",
        element: <ClickToFlipbook />,
      },
      {
        path: "publicprofile",
        element: <Publicprofile />,
      },
      {
        path: "sharepublishing",
        element: <SharePublishing />,
      },
    
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </ThemeProvider>
);

reportWebVitals();
