import React, { useState } from "react";
import SellSettings from "./SellSettings";
import PreviewPages from "./PreviewPages";
import FreeAccesscode from "./FreeAccesscode";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { VscBook } from "react-icons/vsc";
// import { SlBookOpen } from "react-icons/sl";  

export default function Sell() {
  const [toggle, setToggle] = useState(1);
  console.log(toggle);

  const [activeIconTab, setActiveIconTab] = useState("1");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  return (
    <>
      <div className="container-fluid">
      <div className="d-flex justify-content-end py-2">
        </div>
        <div className="bg-white my-3 d-flex justify-content-between">
          <Nav tabs className="p-4 nav-tabs">
            <NavItem className="px-1">
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "1" })}
                onClick={(ev) => {
                  setToggle(1);
                  ev.preventDefault();
                  toggleIconTab("1");
                }}
              >
                <span className="navlinktext">Settings</span>
              </NavLink>
            </NavItem>
            <NavItem className="px-1">
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "2" })}
                onClick={(ev) => {
                  setToggle(2);
                  ev.preventDefault();
                  toggleIconTab("2");
                }}
              >
                <span className="navlinktext">Preview Pages</span>
              </NavLink>
            </NavItem>
            <NavItem className="px-1">
              <NavLink
                tag="a"
                href="#tab"
                className={classnames({ active: activeIconTab === "3" })}
                onClick={(ev) => {
                  setToggle(3);
                  ev.preventDefault();
                  toggleIconTab("3");
                }}
              >
                <span className="navlinktext">Free Access Code</span>
              </NavLink>
            </NavItem>
       
          </Nav>
      
        </div>
      
        <div>
          {toggle === 1 ? (
            <SellSettings />
          ) : toggle === 2 ? (
            <PreviewPages />
          ) : (
            <FreeAccesscode />
          )}
        </div>
      </div>
    </>
  );
}
